import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function useGetChangeHistory(qirId) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState();
    const [changeHistory, setChangeHistory] = useState([]);

    useEffect(() => {
        if (qirId) {
            setLoading(true);
            getChangeHistory(qirId);
        }
    }, [qirId]);

    async function getChangeHistory(qirId) {
        setLoading(true);

        await fetchDataAuthenticated(`api/qir/${qirId}/changeHistory`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setChangeHistory(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get change history", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { changeHistory, loading }
}