import React, { useState } from 'react';
import { DialogInput, RequiredDialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useSaveConfigurationDataProperty } from '../../../../hooks/useConfigurationDataProperties';

function ConfigurationDataPropertyEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const [fieldErrors, setFieldErrors] = useState({});

    const { saveConfigurationDataProperty } = useSaveConfigurationDataProperty();

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Property - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveConfigurationDataProperty} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formConfigurationDataPropertyName" title="Name" placeholder="Property Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formConfigurationDataPropertyDesc" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default ConfigurationDataPropertyEditDialog;