import React, { useState } from 'react';
import { RequiredDialogInput, RequiredDialogSelect, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useSaveGrade, useDeleteGrade } from '../../../../hooks/useGrades';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useTranslation } from 'react-i18next';

function GradeEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { businessUnits } = useGetBusinessUnits();
    const { saveGrade } = useSaveGrade();
    const { deleteFunction } = useDeleteGrade(dialogData);

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Grade - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveGrade} canDelete deleteFunction={deleteFunction}  fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formGradeName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogSelect controlId="formGradeBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </RequiredDialogSelect>
        </CommonConfigDialog>
    );
}

export default GradeEditDialog;