import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import CommentReasonCodeEditDialog from './Dialogs/CommentReasonCodeEditDialog';
import { useGetCommentReasonCodes } from '../../../hooks/useCommentReasonCodes';

function CommentReasonCodesTable({ onRowClick, refresh }) {

    const { commentReasonCodes, loading } = useGetCommentReasonCodes(refresh);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Site',
            accessor: 'siteName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Display',
            accessor: 'display',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="CommentReasonCodes" tableColumns={tableColumns} tableData={commentReasonCodes} />
}

function CommentReasonCodesConfiguration() {
    const getNewCommentReasonCode = () => {
        return { id: null, name: "", businessUnitId: null }
    };

    return (
        <CommonConfigPage
            title="CommentReasonCodes"
            table={(showEditDialog, refreshList) => <CommentReasonCodesTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <CommentReasonCodeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewCommentReasonCode}
        />
    );
}

export default CommentReasonCodesConfiguration;