import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import MultiSelectCheckBox from './MultiSelectCheckbox';

export const handleInputChange = (e, dialogData, setDialogData, fieldErrors, setFieldErrors) => {
    let field = e.currentTarget.name;

    let value = e.currentTarget.value;
    if (e.currentTarget.type === "checkbox") {
        value = e.currentTarget.checked;
    }

    // This is the fix where resetting a select control results in the value being an empty string but it should be null
    if (e.currentTarget.type === "select-one" && e.currentTarget.value === "") {
        value = null;
    }

    // If a display value is cleared, it should be set to null, else the display value is blank
    if (e.currentTarget.name === "display" && e.currentTarget.value === "") {
        value = null;
    }

    //e.currentTarget.setCustomValidity("");

    setDialogData({
        ...dialogData,
        [field]: value
    });

    if (e.currentTarget.validity.valid) {
        setFieldErrors({
            ...fieldErrors,
            [field]: null
        });
    }
    else {
        setFieldErrors({
            ...fieldErrors,
            [field]: e.currentTarget.validationMessage
        });
    }
}

export function RequiredDialogInput({controlId, title, value, name, onChange, error, placeholder}) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Control required type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredSummaryInput({ title, value, name, onChange, error, placeholder }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Control required type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function SummaryInput({ title, value, name, onChange, error, placeholder }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Control type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function DialogIntegerInput({ controlId, title, value, name, onChange, error, placeholder }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Control type="number" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function DialogInput({ controlId, title, value, name, onChange, error, placeholder }) {

    if (value === undefined || value === null)
        value = "";

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Control type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredDialogIntegerInput({ controlId, title, value, name, onChange, error, placeholder }) {

    if (value === undefined || value === null)
        value = "";

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId} key={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Control type="number" required placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredDialogSelect({ children, controlId, title, value, name, onChange, error }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Select required type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredSummarySelect({ children, title, value, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Select required type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function SummarySelect({ children, title, value, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Select type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function DialogSelect({ children, controlId, title, value, name, onChange, error }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <Form.Select type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function DialogSelectWithMoreConfig({ children, controlId, title, value, name, additionalConfig, onChange, error }) {

    if (value === null)
        value = "";

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={8}>
                <Form.Select type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
            <Col sm={1}>
                {additionalConfig}
            </Col>
        </Form.Group>
    );
}

export function DialogCheckbox({controlId, title, disabled, checked, name, onChange, error}) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={5}>
                <Form.Check type="checkbox" size="lg" disabled={disabled}  checked={checked} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function SummaryCheckbox({ title, disabled, checked, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Check type="checkbox" size="lg" disabled={disabled} checked={checked} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function DialogMultiSelect({ options, selectedOptions, controlId, title, name, onChange }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={3}>{title}</Form.Label>
            <Col sm={9}>
                <MultiSelectCheckBox name={name} options={options} selectedOptions={selectedOptions} onChange={onChange} />
            </Col>
        </Form.Group>
    );
}