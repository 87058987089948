import React, { useState } from 'react';
import { RequiredDialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useSaveSite } from '../../../../hooks/useSites';

function SiteEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const [fieldErrors, setFieldErrors] = useState({});

    const { saveSite } = useSaveSite();

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Site - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveSite} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formSiteName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogInput controlId="formSiteCode" title="Site Code" placeholder="Site Code" name="siteCode" value={dialogData.siteCode} error={fieldErrors.siteCode} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default SiteEditDialog;