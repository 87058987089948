import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useGetBusinessUnits } from '../../../hooks/useBusinessUnits';
import BusinessUnitEditDialog from './Dialogs/BusinessUnitEditDialog';

function BusinessUnitsTable({ onRowClick, refresh }) {

    const { businessUnits, loading } = useGetBusinessUnits(refresh);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Site',
            accessor: 'siteName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Display',
            accessor: 'display',
            filter: 'fuzzyText'
        },
        {
            Header: 'AD Group Id',
            accessor: 'adGroupId',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="BusinessUnits" tableColumns={tableColumns} tableData={businessUnits} />
}

function BusinessUnitConfiguration() {
    const getNewBusinessUnit = () => {
        return { id: null, name: "", siteId: null, adGroupId: null, affectedAreas: null, categories: null }
    };

    return (
        <CommonConfigPage
            title="Business Units"
            table={(showEditDialog, refreshList) => <BusinessUnitsTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <BusinessUnitEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewBusinessUnit}
        />
    );
}

export default BusinessUnitConfiguration;