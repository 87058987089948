import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useAddQIRComment } from '../../hooks/useQIRComments';
import AddCommentDialog from '../Dialogs/AddCommentDialog';

export function AddQIRCommentButton({ qir, refresh }) {

    const { t } = useTranslation();
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const { addQIRComment, loading: loadingAddQIRComment } = useAddQIRComment();

    const handleCommentSave = async (comment, commentReasonCodeId) => {
        let success = await addQIRComment(qir.id, comment, commentReasonCodeId);
        if (success) {
            refresh();
        }
    };

    return (
        <>
            <Button variant="primary" onClick={() => setShowCommentDialog(true)}>
                {t('QIRComments.AddComment')}
            </Button>
            {showCommentDialog &&
                <AddCommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} businessUnitId={qir.businessUnitId} onSave={handleCommentSave} />
            }
        </>
    );
}
