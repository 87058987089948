import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';

function CommonConfigPage({ children, title, table, dialog, getNewConfigObject }) {

    if (!title) {
        console.warn("Prop 'title' is used as an identifier in this component. Please supply the prop with a value.")
    }

    const [refreshList, setRefreshList] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ name: "" });

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData(getNewConfigObject());
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        setRefreshList(refreshList + 1);
    };

    return (
        <Container fluid="xs" className="mb-3">
            <Row>
                <Col>
                    {table(showEditDialog, refreshList)}
                </Col>
            </Row>
            <Row>
                <Col>
                    {children}
                    {/*<Button className="float-end" variant="primary" disabled={!permissionToConfigure} onClick={showNewDialog}>Add</Button>*/}
                    <Button className="float-end" variant="primary" onClick={showNewDialog}>Add</Button>
                </Col>
            </Row>

            {dialog(dialogData, setDialogData, showDialog, closeDialog)}

        </Container>
    );
}

export default CommonConfigPage;