import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDeleteQIR } from '../../hooks/useQIRs';
import CommentDialog from '../Dialogs/CommentDialog';
import { DraggableModalDialog } from '../Dialogs/DraggableModalDialog';


function DeleteQIRButton({ qirId }) {

    const { t } = useTranslation();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    return (
        <>
            <Button variant="danger" onClick={() => setShowDeleteConfirmation(true)}>{t('DeleteQIR.DeleteButton')}</Button>
            {showDeleteConfirmation &&
                <DeleteConfirmation qirId={qirId} showDialog={showDeleteConfirmation} closeDialog={() => setShowDeleteConfirmation(false)} />
            }
        </>
    );
}

function DeleteConfirmation({ qirId, showDialog, closeDialog }) {

    const [showDeleteQIRCommentDialog, setShowDeleteQIRCommentDialog] = useState(false);
    const { deleteQIR } = useDeleteQIR();
    const navigate = useNavigate();

    const deleteQIRAndNavigate = async (comment) => {
        await deleteQIR(qirId, comment);
        closeDialog();
        navigate('/');
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this QIR?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={setShowDeleteQIRCommentDialog}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={closeDialog}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showDeleteQIRCommentDialog} closeDialog={() => setShowDeleteQIRCommentDialog(false)} onSave={deleteQIRAndNavigate} />
        </>
    )
}

export default DeleteQIRButton;