import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useGetAffectedAreas } from '../../../hooks/useAffectedAreas';
import AffectedAreaEditDialog from './Dialogs/AffectedAreaEditDialog';
import SelectFilter from '../../DataTable/datatable.selectfilter';

function AffectedAreasTable({ onRowClick, refresh }) {

    const { affectedAreas, loading } = useGetAffectedAreas(refresh);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Site',
            accessor: 'siteName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Display',
            accessor: 'display',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="AffectedAreas" tableColumns={tableColumns} tableData={affectedAreas} />
}

function AffectedAreasConfiguration() {
    const getNewAffectedArea = () => {
        return { id: null, name: "", businessUnitId: null }
    };

    return (
        <CommonConfigPage
            title="Affected Areas"
            table={(showEditDialog, refreshList) => <AffectedAreasTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <AffectedAreaEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewAffectedArea}
        />
    );
}

export default AffectedAreasConfiguration;