import React, { Suspense } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { client as publicClientApplication } from './utilities/AuthUtils';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';

import './custom.css';

import { AlertProvider } from './components/Alerts/AlertContext';
import NotificationAlert from './components/Alerts/NotificationAlert';

import QIRsList from './components/Pages/QIRsList';
import CreateQIR from './components/Pages/CreateQIR';
import QIRDetail from './components/Pages/QIRDetail';
import UpdateQIR from './components/Pages/UpdateQIR';
import { usePrivileges } from './hooks/usePrivileges';
import Configuration from './components/Pages/Configuration';

function LegacyApp(props) {

    const { t } = props;
    const account = publicClientApplication.getAllAccounts()[0];
    const { contributor, admin } = usePrivileges()

    return (
        <>
            <AlertProvider>
                <NotificationAlert />
                <NavMenu title={t("Application.Abbreviation")} />
                <Routes>
                    <Route path='/' exact element={<QIRsList />} />
                    <Route path='/qirs' exact element={<QIRsList />} />
                    <Route path='/qirs/my' exact element={<QIRsList user={account.username} tableName="my-qirs-table" />} />
                    <Route path='/qirs/open' exact element={<QIRsList tableName = "open-qirs-table" />} />
                    <Route path='/qirs/closed' exact element={<QIRsList closed={true} tableName="closed-qirs-table" />} />
                    <Route path='/qir/view/:qirId' exact element={<QIRDetail />} />
                    {contributor &&
                        <>
                            <Route path='/qir/create' exact element={<CreateQIR />} />
                            <Route path='/qir/:qirId' exact element={<UpdateQIR />} />
                        </>
                    }
                    {admin &&
                        <Route path='/config' exact element={<Configuration />} />
                    }
                </Routes>
                <Footer />
            </AlertProvider>
        </>
    );
}

const TranslatedApp = withTranslation()(LegacyApp);

export default function App() {
    return (
        <Suspense fallback="loading">
            <TranslatedApp />
        </Suspense>
    );
}