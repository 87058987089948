import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from './DraggableModalDialog';
import { useGetCommentReasonCodesByBusinessUnit } from '../../hooks/useCommentReasonCodes';

function AddCommentDialog({ showDialog, closeDialog, businessUnitId, onSave }) {

    const { t } = useTranslation();

    const commentReasonCodes = useGetCommentReasonCodesByBusinessUnit(businessUnitId);
    const [comment, setComment] = useState("");
    const [commentReasonCodeId, setCommentReasonCodeId] = useState();
    const [validated, setValidated] = useState(false);

    const onChangeComment = (e) => {
        setComment(e.currentTarget.value);
    }
    const onChangeCommentReasonCode = (e) => {
        setCommentReasonCodeId(e.currentTarget.value);
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            onSave(comment, commentReasonCodeId);
            closeDialog();
        }

        setValidated(true);
    }

    return (
        <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSave} id="commentForm">
                    <Form.Select required value={commentReasonCodeId} size="sm" name="commentReasonCodeId" onChange={onChangeCommentReasonCode} className="mb-3">
                        <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                        {
                            commentReasonCodes && commentReasonCodes.map((reason, i) => {
                                return <option key={i + 1} value={reason.id}>{reason.name}</option>
                            })
                        }
                    </Form.Select>
                    <Form.Control as="textarea" required rows="6" value={comment} onChange={onChangeComment} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    Close
                </Button>
                <Button variant="primary" type="submit" form="commentForm">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCommentDialog;