import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetQIRStatusByBusinessUnit } from '../../hooks/useQIRStatus';
import { useUserBusinessUnits } from '../../hooks/useUsers';
import QIRAssignment from './QIRAssignment';
import ChangeHistory from './ChangeHistory';
import QIRComments from './QIRComments';
import { DateToLocaleString, longDateAndTimeFormat } from '../../utilities/DateUtils';
import { useGetProductionLinesByBusinessUnit } from '../../hooks/useProductionLines';
import { useGetAffectedAreasByBusinessUnit } from '../../hooks/useAffectedAreas';
import { useGetCategoriesByBusinessUnit } from '../../hooks/useCategories';
import { useGetGradesByBusinessUnit } from '../../hooks/useGrades';
import { useGetLocalTimeForBusinessUnit } from '../../hooks/useBusinessUnits'
import { client as publicClientApplication } from '../../utilities/AuthUtils';
import { usePrivileges } from '../../hooks/usePrivileges';


function QIRComponent({ qir, setQir, action, submitButtonText, onSubmit, refresh }) {

    const { t } = useTranslation();
    let search = window.location.search;
    let params = new URLSearchParams(search);

    // Get business units assigned to user
    const userBusinessUnits = useUserBusinessUnits();
    
    // Set business unit from query param 'businessUnit'
    // Or if businessUnitId already exists on the QIR (e.g. update QIR)
    // Or select the only one if only one exists
    var selectedBusinessUnitId;
    const businessUnitQueryParam = params.get('businessUnit');
    if (action === "create" && userBusinessUnits && businessUnitQueryParam) {
        let selectedbusinessUnit = userBusinessUnits.find(l => l.name.toUpperCase() === businessUnitQueryParam.toUpperCase());
        if (selectedbusinessUnit) {
            selectedBusinessUnitId = selectedbusinessUnit.id;
        }
    } else if (qir.businessUnitId) {
        selectedBusinessUnitId = qir.businessUnitId;
    } else if (userBusinessUnits) {
        selectedBusinessUnitId = userBusinessUnits.length === 1 ? userBusinessUnits[0].id : undefined;
    }
    qir.businessUnitId = selectedBusinessUnitId;

    const qirStatuses = useGetQIRStatusByBusinessUnit(selectedBusinessUnitId);
    const selectedStatus = qir.statusId ? qir.statusId : qirStatuses && qirStatuses.length > 0 ? qirStatuses.filter(x => x.stateName === "Open")[0].id : undefined;
    qir.statusId = selectedStatus;

    const productionLines = useGetProductionLinesByBusinessUnit(selectedBusinessUnitId);
    var selectedProductionLineId;
    // Set production line from query param 'line'
    // Or if productionLineId already exists on the QIR (e.g. update QIR)
    const lineQueryParam = params.get('line');
    if (action === "create" && lineQueryParam) {
        let selectedProductionLine = productionLines.find(l => l.name.toUpperCase() === lineQueryParam.toUpperCase());
        if (selectedProductionLine) {
            selectedProductionLineId = selectedProductionLine.id;
            qir.productionLineId = selectedProductionLineId;
        }
    } else {
        selectedProductionLineId = qir.productionLineId;
    }

    const grades = useGetGradesByBusinessUnit(selectedBusinessUnitId);
    sortArrayItems(grades);
    var selectedGradeId;
    // Set grade from query param 'grade'
    // Or if gradeId already exists on the QIR (e.g. update QIR)
    const gradeQueryParam = params.get('grade');
    if (action === "create" && gradeQueryParam) {
        let selectedGrade = grades.find(g => g.name.toUpperCase() === gradeQueryParam.toUpperCase());
        if (selectedGrade) {
            selectedGradeId = selectedGrade.id;
            qir.gradeId = selectedGradeId;
        }
    } else {
        selectedGradeId = qir.gradeId;
    }

    const affectedAreas = useGetAffectedAreasByBusinessUnit(selectedBusinessUnitId);
    sortArrayItems(affectedAreas);
    const categories = useGetCategoriesByBusinessUnit(selectedBusinessUnitId);
    sortArrayItems(categories);

    // Set lotNumber from query param 'lotNumber'
    const lotNumberQueryParam = params.get('lotNumber');
    if (action === "create" && lotNumberQueryParam) {
        qir.lotNumber = lotNumberQueryParam;
    }

    const defaultIncidentDateTime = useGetLocalTimeForBusinessUnit(selectedBusinessUnitId)
    qir.incidentDate = qir.incidentDate ?? defaultIncidentDateTime;

    const { supervisor, admin } = usePrivileges();

    // Filter status list. Only supervisors can close QIRs
    const statusOptions = supervisor ? qirStatuses : qirStatuses.filter(x => x.stateName !== "Closed" && x.stateName !== "Cancelled");

    const account = publicClientApplication.getAllAccounts()[0];

    const [validated, setValidated] = useState(false);

    const onChange = (e) => {
        let field = e.currentTarget.name;
        let value = e.currentTarget.value;

        setQir({
            ...qir,
            [field]: value
        });
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            onSubmit();
        }

        setValidated(true);
    }

    if (userBusinessUnits === undefined) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    const canEditStatement = admin || account.name === qir.originator || account.username === qir.originator;

    return (
        <Container>
            <Form noValidate validated={validated} onSubmit={handleSave} id="createQIR" className="md-5">
                <fieldset disabled={action === "view"}>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="qirId">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.Id')}</Form.Label>
                                <Col sm={9}>
                                    <Form.Control type="text" size="sm" placeholder="<TBD>" value={qir.id && qir.id} name="qirId" onChange={onChange} readOnly />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="statusId">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.Status')}</Form.Label>
                                <Col sm={9}>
                                    <Form.Select required type="text" size="sm" value={selectedStatus} name="statusId" onChange={onChange} >
                                        <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                        {
                                            statusOptions && statusOptions.map((status, i) => {
                                                return <option key={i + 1} value={status.id}>{status.display ?? status.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="businessUnitId">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.BusinessUnit')}</Form.Label>
                                <Col sm={9}>
                                    <Form.Select size="sm" required value={selectedBusinessUnitId} name="businessUnitId" onChange={onChange} disabled={userBusinessUnits && userBusinessUnits.length === 1}>
                                        <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                        {
                                            userBusinessUnits && userBusinessUnits.map((businessUnit, i) => {
                                                return <option key={i + 1} value={businessUnit.id}>{businessUnit.display ?? businessUnit.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="incidentDate">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.IncidentDate')}</Form.Label>
                                <Col sm={9}>
                                    {(action === "create" || action === "update") &&
                                        <Form.Control type="datetime-local" size="sm" value={qir.incidentDate} name="incidentDate" onChange={onChange} />
                                    }
                                    {action === "view" &&
                                        <Form.Control type="text" size="sm" value={qir.incidentDate && DateToLocaleString(qir.incidentDate, longDateAndTimeFormat)} name="incidentDate" onChange={onChange} readOnly />
                                    }
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="productionLineId">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.ProductionLine')}</Form.Label>
                                <Col sm={9}>
                                    <Form.Select size="sm" required value={selectedProductionLineId} name="productionLineId" onChange={onChange}>
                                        <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                        {
                                            productionLines && productionLines.map((line, i) => {
                                                return <option key={i + 1} value={line.id}>{line.display ?? line.name}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group as={Row} className="mb-1" controlId="endDate">
                                <Form.Label column sm={3}>{t('QIRDetail.Fields.EndDate')}</Form.Label>
                                <Col sm={9}>
                                    {(action === "create" || action === "update") &&
                                        <Form.Control type="datetime-local" size="sm" value={qir.endDate} name="endDate" onChange={onChange} />
                                    }
                                    {action === "view" &&
                                        <Form.Control type="text" size="sm" value={qir.endDate && DateToLocaleString(qir.endDate, longDateAndTimeFormat)} name="endDate" onChange={onChange} readOnly />
                                    }
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr />
                    <Form.Group as={Row} className="mb-3" controlId="statement">
                        <Form.Label column sm={2}>{t('QIRDetail.Fields.Statement')}</Form.Label>
                        <Col sm={10}>
                            <Form.Control as="textarea" required size="sm" rows={3} value={qir.statement && qir.statement} name="statement" onChange={onChange} readOnly={!canEditStatement} />
                        </Col>
                    </Form.Group>
                    <Card className="mb-2">
                        <Card.Header>{t('QIRDetail.Sections.Details')}</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="lot">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.Lot')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control required type="text" size="sm" value={qir.lotNumber && qir.lotNumber} name="lotNumber" onChange={onChange} pattern="^[A-Z0-9]*$" />
                                            <Form.Control.Feedback type="invalid">
                                                Lot number must be upper-case alpha-numeric
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="affectedAreaId">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.AffectedArea')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Select required value={qir.affectedAreaId && qir.affectedAreaId} size="sm" name="affectedAreaId" onChange={onChange}>
                                                <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                                {
                                                    affectedAreas && affectedAreas.map((affectedArea, i) => {
                                                        return <option key={i + 1} value={affectedArea.id}>{affectedArea.display ?? affectedArea.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="packageNumber">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.Package')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control type="text" size="sm" value={qir.packageNumber && qir.packageNumber} name="packageNumber" onChange={onChange} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="otherArea">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.OtherArea')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control type="text" size="sm" value={qir.otherArea && qir.otherArea} name="otherArea" onChange={onChange} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="gradeId">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.Grade')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Select required value={selectedGradeId} size="sm" name="gradeId" onChange={onChange}>
                                                <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                                {
                                                    grades && grades.map((grade, i) => {
                                                        return <option key={i + 1} value={grade.id}>{grade.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="categoryId">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.Category')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Select required value={qir.categoryId && qir.categoryId} size="sm" name="categoryId" onChange={onChange}>
                                                <option key="0" value="">{t('Application.Controls.DefaultSelect')}</option>
                                                {
                                                    categories && categories.map((category, i) => {
                                                        return <option key={i + 1} value={category.id}>{category.display ?? category.name}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="originator">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.Originator')}</Form.Label>
                                        <Col sm={9}>
                                            <Form.Control required type="text" size="sm" value={action === "create" ? account.username : qir.createdBy && qir.createdBy} name="originator" onChange={onChange} readOnly />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-1" controlId="dueDate">
                                        <Form.Label column sm={3}>{t('QIRDetail.Fields.DueDate')}</Form.Label>
                                        <Col sm={9}>
                                            {(action === "create" || action === "update") &&
                                                <Form.Control type="datetime-local" size="sm" value={qir.dueDate} name="dueDate" onChange={onChange} />
                                            }
                                            {action === "view" &&
                                                <Form.Control type="text" size="sm" value={qir.dueDate && DateToLocaleString(qir.dueDate, longDateAndTimeFormat)} name="dueDate" onChange={onChange} readOnly />
                                            }
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                    {action !== "create" &&
                        <Card className="mb-2">
                            <Card.Header>{t('QIRDetail.Sections.QIRComments')}</Card.Header>
                            <Card.Body>
                                <QIRComments qir={qir} refresh={refresh} />
                            </Card.Body>
                        </Card>
                    }
                    <Card className="mb-2">
                        <Card.Header>{t('QIRDetail.Sections.Assignment')}</Card.Header>
                        <Card.Body>
                            <QIRAssignment businessUnitId={qir.businessUnitId}
                                controlId="assignedTo"
                                controlName="assignedTo"
                                label={t('QIRDetail.Fields.AssignedTo')}
                                value={qir.assignedTo && qir.assignedTo}
                                onChange={onChange}
                                disabled={action === "view"} />
                        </Card.Body>
                    </Card>
                    <Card className="mb-2">
                        <Card.Header>{t('QIRDetail.Sections.Attachment')}</Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Group as={Row} className="mb-1" controlId="attachment">
                                    <Form.Label column sm={2}>{t('QIRDetail.Fields.Link')}</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" size="sm" value={qir.attachment && qir.attachment} name="attachment" onChange={onChange} />
                                    </Col>
                                </Form.Group>
                            </Row>
                        </Card.Body>
                    </Card>
                    {action !== "create" &&
                        <Card className="mb-2">
                            <Card.Header>{t('QIRDetail.Sections.ChangeHistory')}</Card.Header>
                            <Card.Body>
                                <ChangeHistory qir={qir} />
                            </Card.Body>
                        </Card>
                    }

                    <Row>
                        <Col md={4}>
                            {action !== "create" && !qir.modifiedDate &&
                                <Form.Group as={Row} className="mb-1" controlId="createdDate">
                                    <Form.Label column sm={3}>{t('QIRDetail.Fields.CreatedDate')}</Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="text" size="sm" value={qir.createdDate && DateToLocaleString(qir.createdDate, longDateAndTimeFormat)} name="createdDate" onChange={onChange} readOnly />
                                    </Col>
                                </Form.Group>
                            }
                            {action !== "create" && qir.modifiedDate &&
                                <Form.Group as={Row} className="mb-1" controlId="modifiedDate">
                                    <Form.Label column sm={3}>{t('QIRDetail.Fields.ModifiedDate')}</Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="text" size="sm" value={qir.modifiedDate && DateToLocaleString(qir.modifiedDate, longDateAndTimeFormat)} name="modifiedDate" onChange={onChange} readOnly />
                                    </Col>
                                </Form.Group>
                            }
                        </Col>
                        <Col md={4}>
                            {action !== "create" && !qir.modifiedBy &&
                                <Form.Group as={Row} className="mb-1" controlId="createdBy">
                                    <Form.Label column sm={3}>{t('QIRDetail.Fields.CreatedBy')}</Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="text" size="sm" value={qir.createdBy && qir.createdBy} name="createdBy" onChange={onChange} readOnly />
                                    </Col>
                                </Form.Group>
                            }
                            {action !== "create" && qir.modifiedBy &&
                                <Form.Group as={Row} className="mb-1" controlId="modifiedBy">
                                    <Form.Label column sm={3}>{t('QIRDetail.Fields.ModifiedBy')}</Form.Label>
                                    <Col sm={9}>
                                        <Form.Control type="text" size="sm" value={qir.modifiedBy && qir.modifiedBy} name="modifiedBy" onChange={onChange} readOnly />
                                    </Col>
                                </Form.Group>
                            }
                        </Col>
                        <Col md={4}>
                            {(action === "create" || action === "update") &&
                                <Button type="submit" className="w-100 mb-3">{submitButtonText}</Button>
                            }
                        </Col>
                    </Row>
                </fieldset>
            </Form>
        </Container>
    )
}

export default QIRComponent;

function sortArrayItems(arr) {
    sortArrayItemsAndPutOthersAtTheEnd(arr);
}
function sortArrayItemsAndPutOthersAtTheEnd(arr) {
    arr.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const indexOfOthers = arr.findIndex(x => x.name === "Others");

    // put others at the end of the array if exists
    if (indexOfOthers > -1) {
        arr.push(arr.splice(indexOfOthers, 1)[0]);
    }
}