export const DateToLocaleString = (date, options) => {

    if (date == null) {
        return null;
    }

    return new Date(date).toLocaleString(undefined, options);
}

export const longDateFormat = {
    day: "2-digit",
    month: "short",
    year: "numeric"
};

export const longDateAndTimeFormat = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
};

export const shortDateFormat = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
};

export const shortDateAndTimeFormat = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
};