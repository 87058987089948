import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useUsers } from '../../hooks/useUsers';
import Select from 'react-select';

function QIRAssignment({ controlId, controlName, label, value, businessUnitId, onChange, disabled = false, readonly = false }) {

    const users = useUsers(businessUnitId);
    const options = users ? users.map((user) => ({ value: user, label: user })) : [];

    const [selectedOptions, setSelectedOptions] = useState(value && value.map((user) => ({ value: user, label: user })));

    const handleChange = (o) => {
        let e = {
            currentTarget: {
                name: controlName,
                value: o.map((o) => o.value)
            }
        }

        setSelectedOptions(o);

        onChange(e);
    }

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={2}>{label}</Form.Label>
            <Col sm={10}>
                <Select
                    size="sm"
                    isMulti={true}
                    options={options}
                    value={selectedOptions}
                    name={controlName}
                    onChange={handleChange}
                    isDisabled={disabled || !businessUnitId} />
            </Col>
        </Form.Group>
    )
}

export default QIRAssignment;