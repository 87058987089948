import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export function usePrivileges() {

    const [contributor, setContributor] = useState();
    const [supervisor, setSupervisor] = useState();
    const [admin, setAdmin] = useState();

    useEffect(() => {
        getPrivileges();
    }, []);

    async function getPrivileges() {

        let contributor = await getAuthenticatedAccountPrivilege("Contributor");
        setContributor(contributor);

        let supervisor = await getAuthenticatedAccountPrivilege("Supervisor");
        setSupervisor(supervisor);

        let admin = await getAuthenticatedAccountPrivilege("Admin");
        setAdmin(admin)
    }

    async function getAuthenticatedAccountPrivilege(privilegeName) {
        let accessGranted = false;

        await fetchDataAuthenticated(`api/user/checkPrivilege?privilegeName=${privilegeName}`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(accountHasPrivilege => {
                accessGranted = accountHasPrivilege
            })
            .catch(error => {
                console.error(error);
            });

        return accessGranted;
    }

    return { contributor, supervisor, admin };
}

export function useListPrivileges() {

    const [privileges, setPrivileges] = useState();

    useEffect(() => {
        getPrivileges();
    }, []);

    async function getPrivileges () {
        await fetchDataAuthenticated(`api/user/privileges`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setPrivileges(data);
            })
            .catch(error => {
                console.error(error);
            });
    };

    return { privileges };
}
