import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { client as publicClientApplication, signOut } from '../utilities/AuthUtils';
import { faCog, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../hooks/usePrivileges';

import logo from '../images/cmn-exxonmobil-logo.gif';
import './NavMenu.css';


export default function NavMenu(props) {

    const { t } = useTranslation();
    const { admin } = usePrivileges();

    const account = publicClientApplication.getAllAccounts()[0];

    let titleSuffix = "";
    if (window.location.href.includes("dev")) {
        titleSuffix = "(Development)";
    } else if (window.location.href.includes("acc")) {
        titleSuffix = "(Acceptance)"
    }

    return (
        <header>
            <Navbar expand="lg" bg="white" variant="light" className="border-bottom box-shadow mb-3">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/" className="me-3">
                        <img src={logo}
                            alt="ExxonMobil"
                            width="150"
                            className="d-inline-block align-middle me-3" />
                        <strong>{props.title} {titleSuffix}</strong>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav.Link as={NavLink} to="/qirs/my">
                            <Navbar.Text className="text-dark">{t("NavBar.MyQIRs")}</Navbar.Text>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/qirs/open">
                            <Navbar.Text className="text-dark">{t("NavBar.OpenQIRs")}</Navbar.Text>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/qirs/closed">
                            <Navbar.Text className="text-dark">{t("NavBar.ClosedQIRs")}</Navbar.Text>
                        </Nav.Link>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        {admin &&
                            <Nav.Link as={Link} to="config">
                                <FontAwesomeIcon icon={faCog} className="me-2" />
                                <Navbar.Text className="text-dark">{t("NavBar.ConfigurationLink")}</Navbar.Text>
                            </Nav.Link>
                        }
                        <Nav.Link as={Link}>
                            <FontAwesomeIcon icon={faUser} className="me-2" />
                            <Navbar.Text className="text-dark">{account && account.name}</Navbar.Text>
                        </Nav.Link>
                        <Navbar.Text>
                            <Nav.Link onClick={signOut}>{t("NavBar.SignOut")}</Nav.Link>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}