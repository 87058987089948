import React, { useState } from 'react';
import { DialogInput, DialogSelect, RequiredDialogSelect, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useSaveConfigurationData, useDeleteConfigurationData } from '../../../../hooks/useConfigurationData';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useGetSites } from '../../../../hooks/useSites';
import { useGetConfigurationDataProperties } from '../../../../hooks/useConfigurationDataProperties';
import { useTranslation } from 'react-i18next';

function ConfigurationDataEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { businessUnits } = useGetBusinessUnits();
    const { sites } = useGetSites();
    const { configurationDataProperties } = useGetConfigurationDataProperties();

    const { saveConfigurationData } = useSaveConfigurationData();
    const { deleteFunction } = useDeleteConfigurationData(dialogData);

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Configuration Data - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveConfigurationData} canDelete deleteFunction={deleteFunction} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogSelect controlId="formConfigurationDataProperty" title="Property" name="configurationDataPropertyId" value={dialogData.configurationDataPropertyId} error={fieldErrors.configurationDataPropertyId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {configurationDataProperties.map((prop) => <option value={prop.id} key={prop.id}>{prop.name}</option>)}
            </RequiredDialogSelect>
            <DialogSelect controlId="formConfigurationDataBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </DialogSelect>
            <DialogSelect controlId="formConfigurationDataSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </DialogSelect>
            <DialogInput controlId="formConfigurationDataValue" title="Value" placeholder="Value" name="value" value={dialogData.value} error={fieldErrors.value} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default ConfigurationDataEditDialog;