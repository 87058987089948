import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function SupportDlg(props) {

    SupportDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired
    }

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Application.Abbreviation")} Support
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-start">
                    <FontAwesomeIcon icon={faInfoCircle} color="#4189C7" size="xl" className="me-3 mt-2" />
                    <p>The {t("Application.Title")} ({t("Application.Abbreviation")}) software suite was developed by Jemmac Software Ltd under contract to ExxonMobil.</p>
                </div>

                <p>
                    <strong>Support is provided by:</strong>
                </p>
                <p>Quality Management<br/>SCCOT IT | Manufacturing Operations IT (MOIT)</p>
                <p>If you require support please log a ticket at <a href="https://goto/qmt" target="blank">https://goto/qmt</a></p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SupportDlg;