import React, { useState } from 'react';
import { RequiredDialogInput, DialogInput, handleInputChange, RequiredDialogSelect } from '../DialogInputs';
import { useSaveBusinessUnit } from '../../../../hooks/useBusinessUnits';
import CommonConfigDialog from './CommonConfigDialog';
import { useGetSites } from '../../../../hooks/useSites';
import { useTranslation } from 'react-i18next';

function BusinessUnitEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { saveBusinessUnit } = useSaveBusinessUnit();
    const { sites } = useGetSites();

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Business Unit - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveBusinessUnit} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formBusinessUnitName" title="Name" placeholder="Business Unit Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogSelect controlId="formBusinessUnitSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formBusinessUnitDisplay" title="Display" placeholder="Display" name="display" value={dialogData.display} error={fieldErrors.display} onChange={onChange} />
            <DialogInput controlId="formBusinessUnitADGroupId" title="AD Group" placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX" name="adGroupId" value={dialogData.adGroupId} error={fieldErrors.adGroupId} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default BusinessUnitEditDialog;