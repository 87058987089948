import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';
import { usePrivileges } from '../../hooks/usePrivileges';
import { useGetQIR } from '../../hooks/useQIRs';
import DeleteQIRButton from './DeleteQIRButton';
import QIRComponent from './QIRComponent';

function QIRDetail() {

    const { qirId } = useParams();
    const { qir, loading } = useGetQIR(qirId);
    const { admin } = usePrivileges();

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return qir ?
        <>
            {admin &&
                <div className="d-flex justify-content-end me-3 mb-2">
                    <DeleteQIRButton qirId={qirId} />
                </div>
            }
            <QIRComponent qir={qir} action="view" />
        </>
        : "";
}

export default QIRDetail;