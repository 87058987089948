import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'
export function useGetAffectedAreas(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [affectedAreas, setAffectedAreas] = useState([]);

    useEffect(() => {
        getAffectedAreas();
    }, [refresh]);

    async function getAffectedAreas() {

        await fetchDataAuthenticated("api/affectedArea")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setAffectedAreas(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get affected areas", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { affectedAreas, loading };
}

export function useGetAffectedAreasByBusinessUnit(businessUnitId) {

    const { setAlert } = useAlert();

    const [affectedAreas, setAffectedAreas] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getAffectedAreas(businessUnitId);
        }
    }, [businessUnitId]);

    async function getAffectedAreas(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/affectedAreas`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setAffectedAreas(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get affected areas", error.message);
            })
    }

    return affectedAreas;
}

export function useSaveAffectedArea() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveAffectedArea(affectedArea) {

        let url = "api/affectedArea";
        let method = "POST";
        if (affectedArea.id != null) {
            url += `/${affectedArea.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(affectedArea))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved affected area ${affectedArea.id}`, affectedArea)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to save affected area", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveAffectedArea, loading }
}

export function useDeleteAffectedArea(affectedArea) {
    let url = `api/affectedArea/${affectedArea.id}/delete`;
    let successMessage = `Successfully deleted affected area ${affectedArea.name}`;
    let errorMessage = "Failed to delete affected area";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}