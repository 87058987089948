import React, { useState } from 'react';
import { DialogInput, DialogSelect, RequiredDialogInput, RequiredDialogSelect, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useQirStates, useSaveQIRStatus, useDeleteQIRStatus } from '../../../../hooks/useQIRStatus';
import { useTranslation } from 'react-i18next';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useGetSites } from '../../../../hooks/useSites';

function QIRStatusEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { saveQIRStatus } = useSaveQIRStatus();
    const { deleteFunction } = useDeleteQIRStatus(dialogData);
    const { qirStates } = useQirStates();
    const { businessUnits } = useGetBusinessUnits();
    const { sites } = useGetSites();

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Status - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveQIRStatus} canDelete deleteFunction={deleteFunction}  fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formStatusName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <RequiredDialogSelect controlId="forStatusState" title="State" name="stateId" value={dialogData.stateId} error={fieldErrors.stateId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {qirStates.map((state) => <option value={state.id} key={state.id}>{state.name}</option>)}
            </RequiredDialogSelect>
            <DialogSelect controlId="formStatusBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </DialogSelect>
            <DialogSelect controlId="formStatusSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </DialogSelect>
            <DialogInput controlId="formStatusDisplay" title="Display" placeholder="Display" name="display" value={dialogData.display} error={fieldErrors.display} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default QIRStatusEditDialog;