import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useGetGrades } from '../../../hooks/useGrades';
import GradeEditDialog from './Dialogs/GradeEditDialog';
import SelectFilter from '../../DataTable/datatable.selectfilter';

function GradesTable({ onRowClick, refresh }) {

    const { grades, loading } = useGetGrades(refresh);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="Grades" tableColumns={tableColumns} tableData={grades} />
}

function GradesConfiguration() {
    const getNewGrade = () => {
        return { id: null, name: "", businessUnitId: null }
    };

    return (
        <CommonConfigPage
            title="Grades"
            table={(showEditDialog, refreshList) => <GradesTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <GradeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewGrade}
        />
    );
}

export default GradesConfiguration;