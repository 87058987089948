import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTableFilters, getSortOrder } from '../../utilities/SessionStorage';
import { Spinner } from 'react-bootstrap';
import { useGetChangeHistory } from '../../hooks/useChangeHistory';
import DataTable from '../DataTable/datatable.component';
import DateColumn from '../DataTable/datatable.datecolumn';
import SelectFilter from '../DataTable/datatable.selectfilter';

function ChangeHistory({ qir }) {
    const { t } = useTranslation();
    const { changeHistory, loading } = useGetChangeHistory(qir.id);

    const tableColumns = [
        DateColumn({ header: t('ChangeHistory.ColHead.CommentDate'), accessor: 'commentDate' }),
        {
            Header: t('ChangeHistory.ColHead.Type'),
            accessor: 'type',
            Filter: SelectFilter
        },
        {
            Header: t('ChangeHistory.ColHead.User'),
            accessor: 'account',
            filter: 'includesString'
        },
        {
            Header: t('ChangeHistory.ColHead.Change'),
            accessor: 'comment',
            filter: 'includesString'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    const initialState = {
        pageSize: 10,
        hiddenColumns: ['qirId'],
        sortBy: [
            {
                id: 'commentDate',
                desc: true
            }
        ]
    }

    const tableId = "ChangeHistory";
    const sortBy = getSortOrder(tableId);
    const filters = getTableFilters(tableId);

    if (sortBy) {
        initialState.sortBy = sortBy;
    }

    if (filters) {
        initialState.filters = filters;
    }


    return <>
        {changeHistory.length > 0
            ? <DataTable id={tableId} tableColumns={tableColumns} tableData={changeHistory} initialState={initialState} />
            : <p className="text-center">{t("ChangeHistory.NoComments")}</p>
        }
    </>
}

export default ChangeHistory;