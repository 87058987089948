export const persistSortOrder = (persistKey, sortOrder) => {
    // persist to local storage
    sessionStorage.setItem(`SortOrder.${persistKey}`, JSON.stringify(sortOrder));
}

export const getSortOrder = (persistKey) => {
    let sortOrder = sessionStorage.getItem(`SortOrder.${persistKey}`);

    if (sortOrder) {
        return JSON.parse(sortOrder);
    }
}

export const persistTableFilters = (persistKey, filters) => {
    // persist to local storage
    sessionStorage.setItem(`Filters.${persistKey}`, JSON.stringify(filters));
}

export const getTableFilters = (persistKey) => {
    let tableFilters = sessionStorage.getItem(`Filters.${persistKey}`);
    if (tableFilters) {
        return JSON.parse(tableFilters);
    }
}