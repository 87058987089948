import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal, Row, Col, Card } from 'react-bootstrap';
import exxonLogo from '../../../images/cmn-exxonmobil-logo.gif';
import jemmacLogo from '../../../images/jemmac_logo.png';
import { useUserBusinessUnits } from '../../../hooks/useUsers';


function AboutDlg(props) {

    AboutDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        userName: PropTypes.string.isRequired,
        version: PropTypes.string.isRequired
    }

    const businessUnits = useUserBusinessUnits();

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    About {t("Application.Abbreviation")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>The {t("Application.Title")} software suite is the property of ExxonMobil Corporation. You are responsible for reading the disclaimer below:</p>
                <Card>
                    <Card.Body>
                        <p>Access is restricted to those specifically authorized by ExxonMobil Corporation and its affiliates ("Company"), and is to be used solely for Company-authorized purposes. This system has security facilities to prevent and record unauthorized access attempts.</p>
                        <p>Your authorization to access this system is contingent upon your acknowledgement that ALL FILES, DATA, ELECTRONIC MESSAGES, PROGRAMS, AND OTHER STORED ITEMS PLACED ON THIS SYSTEM BELONG TO THE COMPANY. The Company is likely to examine activity and stored items to the fullest extent permitted by law.</p>
                        <p>Your authorization to access this system is also contingent upon your acceptance of the legal obligation not to use the system in a way that would violate any law or that would be inconsistent with Company policies or standards including those on Ethics, Harassment, Conflict of Interest or Systems Security.</p>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Application.Abbreviation")} Version:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.version}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>User:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.userName}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>Privileges:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{props.privileges && props.privileges.toString()}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>Assigned business units:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{businessUnits && businessUnits.map(bu => bu.name).toString()}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <p className="text-center">Developed by Jemmac Software Ltd under contract to ExxonMobil</p>
                <Row>
                    <Col>
                        <img src={exxonLogo} alt="ExxonMobil logo" className="float-end me-3" />
                    </Col>
                    <Col>
                        <img src={jemmacLogo} alt="Jemmac logo" />
                    </Col>
                </Row>
                <p className="text-center">{props.copywrite}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AboutDlg;