import React from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useGetConfigurationData } from '../../../hooks/useConfigurationData';
import ConfigurationDataEditDialog from './Dialogs/ConfigurationDataEditDialog';


function ConfigurationDataTable({ onRowClick, refresh }) {

    const { configurationData, loading } = useGetConfigurationData(refresh);

    const tableColumns = [
        {
            Header: 'Property',
            accessor: 'configurationDataPropertyName',
            Filter: SelectFilter,
            filter: 'equals',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Site',
            accessor: 'siteName',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Value',
            accessor: 'value',
            filter: 'fuzzyText'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return <DataTable id="ConfigurationData" tableColumns={tableColumns} tableData={configurationData} />
}

function ConfigurationDataConfiguration() {
    const getNewBusinessUnit = () => {
        return { id: null, configurationDataPropertyId: null, businessUnitId: null, value: "" }
    };

    return (
        <CommonConfigPage
            title="Configuration Data"
            table={(showEditDialog, refreshList) => <ConfigurationDataTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <ConfigurationDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewBusinessUnit}
        />
    );
}

export default ConfigurationDataConfiguration;