import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTableFilters, getSortOrder } from '../../utilities/SessionStorage';
import { Spinner } from 'react-bootstrap';
import { useGetQIRComments } from '../../hooks/useQIRComments';
import DataTable from '../DataTable/datatable.component';
import DateColumn from '../DataTable/datatable.datecolumn';
import SelectFilter from '../DataTable/datatable.selectfilter';
import { AddQIRCommentButton } from './AddQIRCommentButton';

function QIRComments({ qir, refresh }) {
    const { t } = useTranslation();
    const { getQirCommentsList, qirComments, loading } = useGetQIRComments(qir.id);

    const tableColumns = [
        DateColumn({ header: t('QIRComments.ColHead.CommentDate'), accessor: 'commentDate' }),
        {
            Header: t('QIRComments.ColHead.Reason'),
            accessor: 'commentReasonCode',
            Filter: SelectFilter
        },
        {
            Header: t('QIRComments.ColHead.User'),
            accessor: 'account',
            filter: 'includesString'
        },
        {
            Header: t('QIRComments.ColHead.Comment'),
            accessor: 'comment',
            filter: 'includesString'
        }
    ];

    const onUpdateCommentList = () => {
        getQirCommentsList(qir.id);
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    const initialState = {
        pageSize: 20,
        hiddenColumns: ['qirId'],
        sortBy: [
            {
                id: 'commentDate',
                desc: true
            }
        ]
    }

    const tableId = "QIRCommentsList";
    const sortBy = getSortOrder(tableId);
    const filters = getTableFilters(tableId);

    if (sortBy) {
        initialState.sortBy = sortBy;
    }

    if (filters) {
        initialState.filters = filters;
    }

    return (
        <>
            {qir.comments.length > 0 &&
                <Row>
                    <DataTable id={tableId} tableColumns={tableColumns} tableData={qir.comments} initialState={initialState} />
                </Row>
            }
            <Row>
                <div className="d-flex justify-content-end">
                    <AddQIRCommentButton qir={qir} refresh={refresh} />
                </div>
            </Row>
        </>
    );
}

export default QIRComments;