import React, { useState } from 'react';
import { DialogInput, DialogSelect, RequiredDialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useGetBusinessUnits } from '../../../../hooks/useBusinessUnits';
import { useGetSites } from '../../../../hooks/useSites';
import { useTranslation } from 'react-i18next';
import { useSaveCommentReasonCodes, useDeleteCommentReasonCodes } from '../../../../hooks/useCommentReasonCodes';

function CommentReasonCodeEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const { t } = useTranslation();

    const [fieldErrors, setFieldErrors] = useState({});

    const { businessUnits } = useGetBusinessUnits();
    const { sites } = useGetSites();
    const { saveCommentReasonCodes } = useSaveCommentReasonCodes();
    const { deleteFunction } = useDeleteCommentReasonCodes(dialogData);

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Comment Reason Code - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveCommentReasonCodes} canDelete deleteFunction={deleteFunction} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} >
            <RequiredDialogInput controlId="formCommentReasonCodeName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogSelect controlId="formCommentReasonCodeBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {businessUnits.map((bu) => <option value={bu.id} key={bu.id}>{bu.name}</option>)}
            </DialogSelect>
            <DialogSelect controlId="formCommentReasonCodeSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                {sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </DialogSelect>
            <DialogInput controlId="formCommentReasonCodeDisplay" title="Display" placeholder="Display" name="display" value={dialogData.display} error={fieldErrors.display} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default CommentReasonCodeEditDialog;