import { useState, useEffect } from 'react';
import { fetchDataAuthenticated, correlationId, fetchBlobAuthenticated } from '../utilities/HttpUtils';
import useAlert from '../components/Alerts/useAlert';

export function useGetQIRs(businessUnits, dateFrom, dateTo, user, closed) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [qirs, setQirs] = useState();

    useEffect(() => {
        setLoading(true);
        if (businessUnits) {
            getQIRs(businessUnits, dateFrom, dateTo, user, closed);
        }
    }, [businessUnits, dateFrom, dateTo, user, closed]);

    async function getQIRs(businessUnits, dateFrom, dateTo, user, closed) {
        let params = new URLSearchParams();

        if (businessUnits && businessUnits.length > 0) {
            businessUnits.map(bu => params.append("businessUnits", bu));
        }

        if (dateFrom) {
            params.append("dateFrom", dateFrom);
        }

        if (dateTo) {
            params.append("dateTo", dateTo);
        }

        if (user) {
            params.append("username", user);
        }

        if (closed !== undefined) {
            params.append("closed", closed);
        }

        await fetchDataAuthenticated("api/qir?" + params.toString())
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setQirs(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", `Failed to get QIRs [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { qirs, getQIRs, loading }
}

export function useGetQIR(qirId) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [qir, setQir] = useState();

    useEffect(() => {
        if (qirId) {
            setLoading(true);
            getQIR(qirId);
        }
    }, [qirId]);

    async function getQIR() {

        await fetchDataAuthenticated(`api/qir/${qirId}`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(qir => {
                setQir(qir);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", `Failed to get QIR ${qirId} [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return { qir, getQIR, loading }
}

export function useCreateQIR() {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState();

    async function createQIR(qir) {

        let qirCreated;
        setLoading(true);

        await fetchDataAuthenticated("api/qir", "POST", JSON.stringify(qir))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setAlert("success", "Success", `Created QIR ${data.id}`);
                qirCreated = true;
            })
            .catch(error => {
                qirCreated = false;
                console.error(error.message);
                setAlert("danger", "Error", `Failed to create QIR [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });

        return qirCreated;
    }

    return { createQIR, loading }
}

export function useUpdateQIR() {

    const { setAlert } = useAlert();

    let qirUpdated;
    const [loading, setLoading] = useState();

    async function updateQIR(qir) {

        setLoading(true);

        await fetchDataAuthenticated("api/qir", "PUT", JSON.stringify(qir))
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Updated QIR ${qir.id}`);
                qirUpdated = true;
            })
            .catch(error => {
                qirUpdated = false;
                console.error(error.message);
                setAlert("danger", "Error", `Failed to update QIR ${qir.id} [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });

        return qirUpdated;
    }

    return { updateQIR, loading }
}

export function useDeleteQIR() {

    const { setAlert } = useAlert();

    let qirDeleted;
    const [loading, setLoading] = useState(true);

    async function deleteQIR(qirId, comment) {

        setLoading(true);

        await fetchDataAuthenticated(`api/qir/${qirId}`, "DELETE", JSON.stringify(comment))
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Deleted QIR ${qirId}`);
                qirDeleted = true;
            })
            .catch(error => {
                qirDeleted = false;
                console.error(error.message);
                setAlert("danger", "Error", `Failed to delete QIR ${qirId} [${correlationId}]`, error.message);
            })
            .finally(() => {
                setLoading(false);
            });

        return qirDeleted;
    }

    return { deleteQIR, loading }
}

export function useQIRReport() {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState();

    async function getQIRReport(businessUnits) {
        setLoading(true);

        console.log(`Getting QIR Report for business units, ${businessUnits}`);

        let params = new URLSearchParams();

        if (businessUnits && businessUnits.length > 0) {
            businessUnits.map(bu => params.append("businessUnits", bu));
        }

        let url = "api/qir/report?" + params.toString();

        console.log(`Requesting QIR Report with '${url}'`);

        await fetchBlobAuthenticated(url)
            .then(async response => {
                if (response.ok) {
                    return response.blob();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then((response) => {
                return response = response.slice(0, response.size, "application/csv");
            })
            .then(blob => {
                downloadFile(blob, `QIR-Report-${getDateString()}.csv`);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const downloadFile = (blob, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click',
            {
                view: window,
                bubbles: true,
                cancelable: true,
            });
        a.dispatchEvent(clickEvt);
        a.remove();
    }

    function getDateString() {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}${month}${day}`
    }

    return { getQIRReport, loading }
}