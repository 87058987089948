import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePrivileges } from '../../hooks/usePrivileges';
import { useGetQIR } from '../../hooks/useQIRs';
import { useUpdateQIR } from '../../hooks/useQIRs';
import DeleteQIRButton from './DeleteQIRButton';
import QIRComponent from './QIRComponent';
import AddCommentDialog from '../Dialogs/AddCommentDialog';

function UpdateQIR() {

    const { t } = useTranslation();
    const { qirId } = useParams(); 
    const { qir: qirDetail, getQIR, loading: loadingGetQIR } = useGetQIR(qirId);
    const { updateQIR, loading: loadingUpdateQIR } = useUpdateQIR();
    const [qir, setQir] = useState();
    const { admin } = usePrivileges();
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    useEffect(() => {
        setQir(qirDetail);
    }, [qirDetail]);

    const saveChanges = async (comment, commentReasonCodeId) => {
        qir.comment = comment;
        qir.commentReasonCodeId = commentReasonCodeId;
        let success = await updateQIR(qir)
        if (success) {
            getQIR();
        }
    }

    if (loadingGetQIR || loadingUpdateQIR) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <>
            {admin &&
                <div className="d-flex justify-content-end me-3 mb-2">
                    <DeleteQIRButton qirId={qirId} />
                </div>
            }
            <QIRComponent qir={qir} setQir={setQir} action="update" submitButtonText={t("UpdateQIR.UpdateButton")} onSubmit={() => setShowCommentDialog(true)} refresh={getQIR} />
            <AddCommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} businessUnitId={qir.businessUnitId} onSave={saveChanges} />
        </>
        
    )
}

export default UpdateQIR;