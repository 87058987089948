import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useCreateQIR } from '../../hooks/useQIRs';
import QIRComponent from './QIRComponent';
import { client as publicClientApplication } from '../../utilities/AuthUtils';

function CreateQIR() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { createQIR, loading } = useCreateQIR();

    const [qir, setQir] = useState({});

    const account = publicClientApplication.getAllAccounts()[0];

    useEffect(() => {
        setQir({
            ...qir,
            originator: account.name
        });
    }, []);

    const createQIRAndNavigateBackToList = async () => {

        let success = await createQIR(qir);

        if (success) {
            navigate("/qirs");
        }
    }

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <QIRComponent qir={qir} setQir={setQir} action="create" submitButtonText={t("CreateQIR.CreateButton")} onSubmit={createQIRAndNavigateBackToList} />
    )
}

export default CreateQIR;