import { createContext, useState } from 'react';

const initialState = {
    showAlert: false,
    variant: "",
    header: "",
    text: "",
    exceptionMessage: ""
};

const AlertContext = createContext({
    ...initialState,
    setAlert: () => { },
    closeAlert: () => { }
});

export const AlertProvider = ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [variant, setVariant] = useState("");
    const [header, setHeader] = useState("");
    const [text, setText] = useState("");
    const [exceptionMessage, setExceptionMessage] = useState("");

    const setAlert = (variant, header, text, exceptionMessage) => {
        setShowAlert(true);
        setVariant(variant);
        setHeader(header);
        setText(text);
        setExceptionMessage(exceptionMessage);

        setTimeout(() => {
            setShowAlert(false);
            setVariant("");
            setHeader("");
            setText("");
            setExceptionMessage("");
        }, 10000);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <AlertContext.Provider value={{ showAlert, variant, header, text, exceptionMessage, setAlert, closeAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;